import styled, { css } from "styled-components"
import { Link } from "gatsby"

import { COLORS, SIZES, BREAKPOINTS } from "src/constants"
import Image from "src/components/Image"

export const Content = styled.div`
  background-color: ${COLORS.light};
  position: relative;
  padding-top: 20px;
`
export const Section = styled.section`
  padding: 0 60px;
  max-width: ${BREAKPOINTS.maxWidth}px;
  margin: 0 auto;
  @media (max-width: ${BREAKPOINTS.tablet}px) {
    padding: 0 20px;
  }
`
export const Title = styled.h1<{ space?: boolean }>`
  color: ${COLORS.light};
  font-size: ${SIZES.big};
  max-width: ${BREAKPOINTS.maxTextWidth}px;
  line-height: 1.5em;
  display: inline-block;
  margin: ${(props): string => (props.space ? "60px 0 0" : "0")};
  span {
    box-shadow: 10px 0 0 0 ${COLORS.main}, -10px 0 0 0 ${COLORS.main};
    background-color: ${COLORS.main};
    box-decoration-break: clone;
    line-height: 0.1em;
    margin-left: 5px;
  }
  @media (max-width: ${BREAKPOINTS.tablet}px) {
    margin: 0;
    font-size: ${SIZES.medium};
    span {
      box-shadow: 6px 0 0 0 ${COLORS.main}, -6px 0 0 0 ${COLORS.main};
    }
  }
`
export const Subtitle = styled.h2`
  color: ${COLORS.main};
  font-size: ${SIZES.huge};
  @media (max-width: ${BREAKPOINTS.tablet}px) {
    font-size: ${SIZES.big};
  }
  margin: 20px 0;
`
export const SectionTitle = styled.h3`
  color: ${COLORS.main};
  max-width: ${BREAKPOINTS.maxTextWidth}px;
  font-size: ${SIZES.large};
  @media (max-width: ${BREAKPOINTS.tablet}px) {
    font-size: ${SIZES.normal};
  }
`
export const Description = styled.p`
  font-weight: bold;
  max-width: ${BREAKPOINTS.maxTextWidth}px;
  font-size: ${SIZES.desc};
  margin: 60px 0;
  @media (max-width: ${BREAKPOINTS.tablet}px) {
    font-size: ${SIZES.medium};
    margin: 30px 0;
  }
`
export const Text = styled.p`
  font-size: ${SIZES.normal};
  font-weight: bold;
  max-width: ${BREAKPOINTS.maxTextWidth}px;
  a {
    text-decoration: underline;
  }
  a.tag {
    text-decoration: none;
  }
`
export const RedText = styled(Text)`
  color: ${COLORS.main};
  a {
    color: inherit;
  }
`

const ctaCss = css`
  border: 4px solid ${COLORS.main};
  color: ${COLORS.main};
  display: inline-flex;
  align-items: center;
  font-weight: bold;
  span:first-child {
    text-align: right;
    margin-left: 10px;
  }
  span:last-child {
    text-align: left;
    margin-right: 10px;
  }
  svg {
    flex: 0 0 auto;
  }
  svg:first-child {
    margin-right: 20px;
  }
  svg:last-child {
    margin-left: 20px;
  }
  @media (max-width: ${BREAKPOINTS.tablet}px) {
    padding: 8px;
    svg:first-child {
      margin-right: 12px;
    }
    svg:last-child {
      margin-left: 12px;
    }
  }
`
const bigCta = css`
  font-size: ${SIZES.large};
  line-height: 3rem;
  padding: 15px;
  @media (max-width: ${BREAKPOINTS.tablet}px) {
    svg {
      width: 45px;
      height: 50px;
    }
    font-size: ${SIZES.medium};
    line-height: 1.5rem;
    border-width: 2px;
  }
`

export const InternalCta = styled(Link)`
  ${ctaCss}
  ${bigCta}
`
export const ScrollCta = styled.button`
  ${ctaCss}
  font-size: ${SIZES.normal};
  line-height: ${SIZES.normal};
  border-width: 2px;
  padding: 10px 15px;
  svg {
    width: 45px;
    height: 50px;
  }
  margin-bottom: 30px;
`

// index & gift card:

export const InfosContainer = styled.section`
  background-color: ${COLORS.main};
  margin: 80px 0 120px;
  @media (max-width: ${BREAKPOINTS.tablet}px) {
    margin: 60px 0;
  }
`
export const Infos = styled.div`
  margin: 0 auto;
  max-width: ${BREAKPOINTS.maxWidth}px;
  color: ${COLORS.light};
  position: relative;
  padding: 30px 20px;
  @media (min-width: ${BREAKPOINTS.tablet}px) {
    display: flex;
    padding: 50px;
  }
`
export const InfosImage = styled(Image)`
  width: 185px;
  height: 182px;
  margin: 65px 20px;
  @media (max-width: ${BREAKPOINTS.tablet}px) {
    position: absolute !important;
    top: 20px;
    right: 10px;
    width: 75px;
    height: 75px;
    margin: 0;
  }
`
export const InfosContent = styled.div`
  flex: 1;
  ${RedText} {
    background-color: ${COLORS.light};
    padding: 2px 5px;
  }
`
export const InfosTitle = styled.h2`
  text-decoration: underline;
  font-size: ${SIZES.large};
  margin-top: 0;
  @media (max-width: ${BREAKPOINTS.tablet}px) {
    font-size: ${SIZES.medium};
    margin-right: 60px;
  }
`
export const InfosSubtitle = styled.h3`
  text-decoration: underline;
  margin-bottom: 0;
  @media (max-width: ${BREAKPOINTS.tablet}px) {
    font-size: ${SIZES.small};
  }
`
export const InfosText = styled.div`
  font-size: ${SIZES.normal};
  @media (max-width: ${BREAKPOINTS.tablet}px) {
    font-size: ${SIZES.small};
  }
  font-weight: bold;
  white-space: pre-line;
  a {
    color: ${COLORS.light};
  }
  ol {
    margin-left: 20px;
    margin-block-start: 0.5em;
    margin-block-end: 1.5em;
    li {
      margin-left: 33px;
      padding-left: 0;
    }
  }
  @media (max-width: ${BREAKPOINTS.tablet}px) {
    ol {
      margin-left: 0px;
      padding-inline-start: 0;
      li {
        margin-left: 23px;
        padding-left: 0;
      }
    }
  }
`

// values & gift card:

export const RowSection = styled(Section)`
  display: flex;
  margin: 0 auto;
  ${SectionTitle} {
    margin-top: 0;
  }
  @media (max-width: ${BREAKPOINTS.tablet}px) {
    display: block;
    margin: 0;
  }
`
export const Column = styled.div`
  margin: 40px;
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
  @media (max-width: ${BREAKPOINTS.tablet}px) {
    margin: 0 0 30px;
    svg {
      width: 100%;
    }
  }
`
export const Answer = styled.h4`
  font-size: ${SIZES.medium};
  text-decoration: underline;
  margin: 0;
`
export const Illustration = styled(Image)`
  width: 500px;
  max-width: 100%;
`
export const Heading = styled.span`
  text-decoration: underline;
  font-size: 1.1em;
`

// index & workshops:

export const Features = styled.div`
  display: flex;
`

export const Feature = styled.figure`
  display: inline-block;
  text-align: center;
  width: 130px;
  margin: 30px 60px 0 0;
  svg {
    width: 80px;
    height: 80px;
    background-color: ${COLORS.main};
    border-radius: 40px;
    margin: 10px 0;
    fill: ${COLORS.light};
    [fill="${COLORS.main}"] {
      fill: ${COLORS.light};
    }
    [stroke="${COLORS.main}"] {
      stroke: ${COLORS.light};
    }
  }
  @media (max-width: ${BREAKPOINTS.tablet}px) {
    margin: 15px 15px 0 0;
    svg {
      width: 40px;
      height: 40px;
      margin: 5px 0;
    }
  }
  &:last-child {
    margin-right: 0;
  }
`
export const FeatCaption = styled.figcaption`
  color: ${COLORS.main};
  font-weight: bold;
  white-space: pre-line;
  font-size: ${SIZES.normal};
  @media (max-width: ${BREAKPOINTS.tablet}px) {
    font-size: 0.75em;
  }
`

export const ImageCaption = styled.figcaption`
  font-size: ${SIZES.normal};
  font-weight: bold;
  white-space: pre-line;
  max-width: ${BREAKPOINTS.maxTextWidth}px;
  margin: 8px 0 60px;
  @media (max-width: ${BREAKPOINTS.tablet}px) {
    margin: 8px 0 40px;
  }
`

export const BookSection = styled(Section)`
  margin-top: 20px;
  display: flex;
  > svg {
    width: 60px;
    height: 67px;
    margin-right: 30px;
  }
  padding-top: 30px;
  @media (max-width: ${BREAKPOINTS.mobile}px) {
    display: block;
    > svg {
      margin-bottom: 20px;
    }
  }
`
export const Book = styled.div`
  flex: 1;
`
export const BookTitle = styled(SectionTitle)`
  margin: 0 0 5px;
  text-transform: uppercase;
`
export const BookSubtitle = styled(SectionTitle)`
  margin: 0;
  font-weight: normal;
  margin-bottom: 40px;
  @media (max-width: ${BREAKPOINTS.mobile}px) {
    margin-bottom: 20px;
  }
`
