import React from "react"
import GatsbyImage, { GatsbyImageProps } from "gatsby-image"
import styled from "styled-components"

import { ContentfulImage, ImageNode } from "src/types"
import { BREAKPOINTS } from "src/constants"

interface Props {
  file?: ContentfulImage | ImageNode["childImageSharp"]
  className?: string
}

const Image: React.FC<Props & GatsbyImageProps> = ({ file, ...props }) => {
  if (file) {
    return <GatsbyImage {...file} alt="" {...props} />
  }
  return (
    <div {...props}>
      <div className="empty" />
    </div>
  )
}

export default Image

const Container = styled.div`
  width: 100%;
  flex-shrink: 0;
  :after {
    content: "";
    display: block;
    padding-bottom: 80%;
  }
  display: flex;
  justify-content: center;
  align-items: center;
`

interface RectProps extends Props {
  ratio: number
}

export const RectImage: React.FC<RectProps & GatsbyImageProps> = ({ ratio, file: { fluid }, className, ...props }) => {
  const style = {
    width: `${100 * Math.min(1, fluid.aspectRatio / ratio)}%`,
  }
  return (
    <Container className={className}>
      <GatsbyImage fluid={fluid} style={style} {...props} />
    </Container>
  )
}

export const ResponsiveImageSwitcher = styled.div`
  .desktop {
    display: none;
  }
  @media (min-width: ${BREAKPOINTS.mobile}px) {
    .desktop {
      display: block;
    }
    .mobile {
      display: none;
    }
  }
`
